<template>
    <div class="timeline">

<head>
  <meta charset="UTF-8">
  <link rel='stylesheet' href='https://stackpath.bootstrapcdn.com/bootswatch/3.3.7/slate/bootstrap.min.css'>
<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'><link rel="stylesheet" href="./style.css">

</head>

<!-- partial:index.partial.html -->
<div class="container">
  <div class="timeline">
    <div class="timeline-month">
      May, 2022
      <span>3 Entries</span>
    </div>
    <div class="timeline-section">
      <div class="timeline-date">
        15, Sunday
      </div>
      <div class="row">
        <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-info" aria-hidden="true"></i> Clown Collective was born
              </div>
              <div class="box-content">
                <div class="box-item"><strong>Theme</strong>: Clown</div>
                <div class="box-item"><strong>Directive</strong>: Wave slaves by day, degens by night. We are the Clown Collective, our home is the Night Circus.</div>
              </div>
              <div class="box-footer">- Styyyle</div>
            </div>
          </div>
        <div class="col-sm-4">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-success" aria-hidden="true"></i> Gen Zero Mint
            </div>
            <div class="box-content">
              <div class="box-item"><strong>Supply</strong>: 22</div>
              <div class="box-item"><strong>Price</strong>: 6.9</div>
              <div class="box-item"><strong>Mint type</strong>: 24h WL</div>
            </div>
            <div class="box-footer">- Guios</div>
          </div>
        </div>
      </div>
    </div>

    <div class="timeline-section">
      <div class="timeline-date">
        26, Thursday
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-info" aria-hidden="true"></i> First Wave of Invites
            </div>
            <div class="box-content">
              <div class="box-item"><strong>Mission</strong>: All Clowns individually invite 8 “inner circle” members</div>
              <div class="box-item"><strong>Approach</strong>: Mysterious, limited information tweets from CC account, guardians retweet/interact</div>
              <div class="box-item"><strong>No. Of invitees</strong>: 8 per Gardian</div>
            </div>
            <div class="box-footer">- Duke</div>
          </div>
        </div>
    </div>
    </div>
    <div class="timeline-month">
        June, 2022
        <span>3 Entries</span>
      </div>
    <div class="timeline-section">
      <div class="timeline-date">
        T - 10 Weeks
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="timeline-box">
            <div class="box-title">
              <i class="fa fa-pencil text-success" aria-hidden="true"></i> Puzzle Time
            </div>
            <div class="box-content">
              <div class="box-item"><strong>Puzzle</strong>: start a circus map puzzle campaign - one each week until mint day (could be image from mint page in 10 pieces)</div>
            </div>
            <div class="box-footer">- Rocky</div>
          </div>
        </div>
      </div>
    </div>

    <div class="timeline-section">
        <div class="timeline-date">
          T - 8 Weeks
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> How to WL campaign
              </div>
              <div class="box-content">
                <div class="box-item"><strong>Hints</strong>: </div>
                <div class="box-item"><strong>     </strong>    Fan Art</div>
                <div class="box-item"><strong>     </strong>    Voice</div>
                <div class="box-item"><strong>     </strong>    Talent show (meaningful skills to teach others)</div>
                <div class="box-item"><strong>     </strong>    Uggest clown traits to include in generative - be wild, the crazier the better</div>
              </div>
              <div class="box-footer">- Duke</div>
            </div>
          </div>
        </div>
      </div>

      <div class="timeline-section">
        <div class="timeline-date">
          T - 6 Weeks
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> OG WL
              </div>
              <div class="box-content">
                <div class="box-item"><strong>OG</strong>: Close early bird WL at 500pax</div>
                <div class="box-item"><strong>Twitter</strong>: 3000 Twitter followers</div>
            </div>
              <div class="box-footer">- Styyyle</div>
            </div>
          </div>
        </div>
      </div>

      <div class="timeline-month">
        July, 2022
        <span>4 Entries</span>
      </div>

      <div class="timeline-section">
        <div class="timeline-date">
          T - 4 Weeks
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> WL 2
              </div>
              <div class="box-content">
                <div class="box-item"><strong>OG</strong>: Close WL round 2 @1000pax</div>
                <div class="box-item"><strong>Twitter</strong>: 5000 Twitter followers</div>
            </div>
              <div class="box-footer">- Duke</div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> Influencers
              </div>
              <div class="box-content">
                <div class="box-item"><strong>Marketing</strong>: First influencer posts</div>
                <div class="box-item"><strong>Twitter</strong>: Ramp Twitter activity and twitter/discord engagement campaign</div>
                <div class="box-item"><strong>Discord</strong>: DAO onboarding begins w/few select DAOs
                </div>
            </div>
              <div class="box-footer">- Duke</div>
            </div>
          </div>
        </div>
      </div>
      

      <div class="timeline-section">
        <div class="timeline-date">
          T - 2 Weeks
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> Puzzle Time
              </div>
              <div class="box-content">
                <div class="box-item"><strong>Marketing</strong>: Influencer posts increase                </div>
                <div class="box-item"><strong>Games</strong>: Discord Engagement strategy - games                </div>
                <div class="box-item"><strong>Discord</strong>: DAO onboarding continues w/expanded group
                <div class="box-item"><strong>Twitter</strong>: 8000 Twitter followers</div>
            </div>
              </div>
              <div class="box-footer">- Rocky</div>
            </div>
          </div>
        </div>
      </div>

      <div class="timeline-section">
        <div class="timeline-date">
          T - 10 Days
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> Puzzle Time
              </div>
              <div class="box-content">
                <div class="box-item"><strong>Marketing</strong>: Initial Twitter WL give aways                </div>
                <div class="box-item"><strong>Twitter</strong>: 10 000 Twitter followers</div>
            </div>
              </div>
              <div class="box-footer">- Rocky</div>
            </div>
          </div>
        </div>
        
      <div class="timeline-month">
        August, 2022
        <span>4 Entries</span>
      </div>

      <div class="timeline-section">
        <div class="timeline-date">
          T - 7 Days
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> Puzzle Time
              </div>
              <div class="box-content">
                <div class="box-item"><strong>Twitter</strong>: 12 000 Twitter followers</div>
            </div>
              </div>
              <div class="box-footer">- Rocky</div>
            </div>
          </div>
        </div>

      <div class="timeline-section">
        <div class="timeline-date">
          T - 3 Days
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> Puzzle Time
              </div>
              <div class="box-content">
                <div class="box-item"><strong>Twitter</strong>: 15 000 Twitter followers</div>
            </div>
              </div>
              <div class="box-footer">- Rocky</div>
            </div>
          </div>
        </div>

      <div class="timeline-section">
        <div class="timeline-date">
          T - 48 Hours
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> Puzzle Time
              </div>
              <div class="box-content">
                <div class="box-item"><strong>Twitter</strong>: 15 000 Twitter followers</div>
            </div>
              </div>
              <div class="box-footer">- Rocky</div>
            </div>
          </div>
        </div>
        

      <div class="timeline-section">
        <div class="timeline-date">
          T - 24 Hours
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="timeline-box">
              <div class="box-title">
                <i class="fa fa-pencil text-success" aria-hidden="true"></i> Puzzle Time
              </div>
              <div class="box-content">
                <div class="box-item"><strong>Twitter</strong>: 15 000+ Twitter followers</div>
            </div>
              </div>
              <div class="box-footer">- Rocky</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>





  
  </template>


<style scoped>


.timeline{
  margin-top:20px;
  position:relative;
  
}

.timeline:before{
  position:absolute;
  content:'';
  width:4px;
  height:calc(100% + 50px);
background: rgb(138,145,150);
background: -moz-linear-gradient(left, rgba(138,145,150,1) 0%, rgba(122,130,136,1) 60%, rgba(98,105,109,1) 100%);
background: -webkit-linear-gradient(left, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(98,105,109,1) 100%);
background: linear-gradient(to right, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(98,105,109,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a9196', endColorstr='#62696d',GradientType=1 );
  left:14px;
  top:5px;
  border-radius:4px;
}

.timeline-month{
  position:relative;
  padding:4px 15px 4px 35px;
  background-color:#444950;
  display:inline-block;
  width:auto;
  border-radius:40px;
  border:1px solid #17191B;
  border-right-color:black;
  margin-bottom:30px;
}

.timeline-month span{
  position:absolute;
  top:-1px;
  left:calc(100% - 10px);
  z-index:-1;
  white-space:nowrap;
  display:inline-block;
  background-color:#111;
  padding:4px 10px 4px 20px;
  border-top-right-radius:40px;
  border-bottom-right-radius:40px;
  border:1px solid black;
  box-sizing:border-box;
}

.timeline-month:before{
  position:absolute;
  content:'';
  width:20px;
  height:20px;
background: rgb(138,145,150);
background: -moz-linear-gradient(top, rgba(138,145,150,1) 0%, rgba(122,130,136,1) 60%, rgba(112,120,125,1) 100%);
background: -webkit-linear-gradient(top, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(112,120,125,1) 100%);
background: linear-gradient(to bottom, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(112,120,125,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a9196', endColorstr='#70787d',GradientType=0 );
  border-radius:100%;
  border:1px solid #17191B;
  left:5px;
}

.timeline-section{
  padding-left:35px;
  display:block;
  position:relative;
  margin-bottom:30px;
}

.timeline-date{
  margin-bottom:15px;
  padding:2px 15px;
  background:linear-gradient(#74cae3, #5bc0de 60%, #4ab9db);
  position:relative;
  display:inline-block;
  border-radius:20px;
  border:1px solid #17191B;
  color:#fff;
text-shadow:1px 1px 1px rgba(0,0,0,0.3);
}
.timeline-section:before{
  content:'';
  position:absolute;
  width:30px;
  height:1px;
  background-color:#444950;
  top:12px;
  left:20px;
}

.timeline-section:after{
  content:'';
  position:absolute;
  width:10px;
  height:10px;
  background:linear-gradient(to bottom, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(112,120,125,1) 100%);
  top:7px;
  left:11px;
  border:1px solid #17191B;
  border-radius:100%;
}

.timeline-section .col-sm-4{
  margin-bottom:15px;
}

.timeline-box{
  position:relative;
  
 background-color:#444950;
  border-radius:15px;
  border-top-left-radius:0px;
  border-bottom-right-radius:0px;
  border:1px solid #17191B;
  transition:all 0.3s ease;
  overflow:hidden;
}

.box-icon{
  position:absolute;
  right:5px;
  top:0px;
}

.box-title{
  padding:5px 15px;
  border-bottom: 1px solid #17191B;
}

.box-title i{
  margin-right:5px;
}

.box-content{
  padding:5px 15px;
  background-color:#17191B;
}

.box-content strong{
  color:#666;
  font-style:italic;
  margin-right:5px;
}

.box-item{
  margin-bottom:5px;
}

.box-footer{
 padding:5px 15px;
  border-top: 1px solid #17191B;
  background-color:#444950;
  text-align:right;
  font-style:italic;
}
</style>